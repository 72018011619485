import React from "react";
import Brightness1SharpIcon from "@material-ui/icons/Brightness1Sharp";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    startIcon: {
        color: "#D9D9D9",
        textAlign: "right",
        marginRight: "5px",
        width: "25px",
        height: "24px",
    },
    listItem: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "10px",
        paddingTop: "10px",
        fontSize: "16px",
        "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            borderBottom: "1px solid #ccc",
        },
    },
}));

const AdminList = ({ admins, showOnlyManagers, showOnlySalon }) => {
    const classes = useStyles();

    return (
        <div style={{border: "1px solid #000", marginTop: "24px"}}>
            <ul style={{listStyle: "none", paddingLeft: "0px"}}>
                {admins.filter((admin) =>
                    (!showOnlyManagers || admin.department_id === 1) &&
                    (!showOnlySalon || admin.department_id === 7)).map((admin) => (
                    <li className={classes.listItem} key={admin.id}>
                        <div style={{marginLeft: "5px"}}>{admin.name}</div>
                        <Brightness1SharpIcon className={classes.startIcon}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default AdminList;