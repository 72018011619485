import React, {useState, useEffect, useCallback, useContext} from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle, makeStyles, TextField} from "@material-ui/core";
import {Grid, IconButton} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import SearchField from "../../components/fields/SearchField";
import PageTitle from "../../components/typography/PageTitle";
import LeftBar from "../../components/left-bar/LeftBar";
import SortIcon from '@material-ui/icons/Sort';
import {getSegments} from "../../services/segments";
import SegmentContext from "./SegmentContext";
import {ArrowRightAltOutlined} from "@material-ui/icons";
import ActionButton from "../../components/button/ActionButton";

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: "100%",
        position: "relative",
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
    icon: {
        color: "#3c1a53",
        padding: 0,
        transform: "scaleX(-1)",
    },
    sortButton: {
        textAlign: "right",
        padding: "0px",
        marginTop: "10px",
        right: 0,
    },
    startBtn: {
        width: "14px",
        height: "16px",
        marginTop: "4px",
    },
    startIcon: {
        color: "#3c1a53",
        textAlign: "right",
    },
    flip: {
        transform: "rotate(180deg)",
    },
    listItem: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "10px",
        paddingTop: "10px",
        fontSize: "16px",
        "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: "90%",
            borderBottom: "1px solid #ccc",
        },
    },
    left: {
        width: "20.83%",
    },
}));

const Left = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [sortIconFlipped, setSortIconFlipped] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [segments, setSegments] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredSegments, setFilteredSegments] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalInputValue, setModalInputValue] = useState('');
    const {selectedSegments, setSelectedSegments} = useContext(SegmentContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getSegments( null, -1);
                if (response.success) {
                    const segmentsWithBlank = response.data.map((segment) => {
                        return {
                            ...segment,
                            blank: true
                        }
                    })
                    setSegments(segmentsWithBlank);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();
    }, []);

    const handleSearch = useCallback((event) => {
        setSearchText(event.target.value);
    }, []);

    const handleClickSearch = useCallback( () => {
        const filtered = segments.filter(segment => {
            if (selectedSegments.some(s => s.id === segment.id)){
                return false;
            }
            return segment.name.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredSegments(filtered);
    }, [segments, searchText, selectedSegments]);

    useEffect(() => {
        handleClickSearch();
    }, [segments, searchText, handleClickSearch]);

    const handleSortClick = () => {
        setSortIconFlipped(!sortIconFlipped);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setFilteredSegments(prevSegments => prevSegments.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        }));
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalInputValue('');
        setSelectedSegments((prev) => prev.slice(0, prev.length - 1));
    };

    const addClick = (segment) => {
        setSelectedSegments((prev) => [...prev, segment]);
        setModalInputValue(segment.whatToDo || '');
        setOpenModal(true);
    };

    const handleModalConfirm = () => {
        const updatedSegments = selectedSegments.map((segment) => {
            return {
                ...segment,
                whatToDo: modalInputValue,
            };
        });

        console.log('updatedSegments', updatedSegments);
        setSelectedSegments(updatedSegments);
        setOpenModal(false);
        setModalInputValue('');
    };

    return (
        <div className={classes.left}>
            <LeftBar classes={{drawerPaper: classes.drawerPaper}}>
                <PageTitle>{t("Segments")}</PageTitle>
                <SearchField
                    value={searchText}
                    onChange={handleSearch}
                    label={t("Search")}
                    onClick={handleClickSearch}
                />
                <Grid container justifyContent="flex-end">
                    <IconButton className={classes.sortButton} onClick={handleSortClick}>
                        <SortIcon className={`${classes.icon}  ${sortIconFlipped ? classes.flip : ""}`}/>
                    </IconButton>
                </Grid>
                <div>
                    <ul style={{listStyle: "none", paddingLeft: "0px"}}>
                        {filteredSegments.map((segment) => (
                                <li className={classes.listItem} key={segment.id}>
                                    {segment.name}
                                    <IconButton className={classes.startBtn} onClick={() => addClick(segment)}>
                                        <ArrowRightAltOutlined className={classes.startIcon}/>
                                    </IconButton>
                                </li>
                        ))}
                    </ul>
                </div>
            </LeftBar>
            {openModal && (
                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Внимание!</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Укажите нужные действия для администраторов если они не указаны</DialogContentText>
                    </DialogContent>
                    <TextField
                    value={modalInputValue !== null ? modalInputValue : ''}
                    onChange={(e) => setModalInputValue(e.target.value)}
                    />
                    <ActionButton onClick={handleModalConfirm} disabled={!modalInputValue}>{t("Save")}</ActionButton>
                </Dialog>
            )}
        </div>
    );
}

export default Left;