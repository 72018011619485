import baseRequest from "./baseReuqest";

export function saveRowProcess(data) {
    console.log('что уходит dataRow', data)
    return baseRequest(
        `/api/create-process-by-call-center`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function stopProcessesByCallCenter(data) {
    return baseRequest(
        "/api/stop-batch-processes",
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function stopRowProcessByCallCenter(data) {
    return baseRequest(
        "/api/stop-process",
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function getProcessesForTable() {
    const test = baseRequest('/api/get-processes-for-table');
    console.log('test', test);
    return test;
}

export function saveAllProcess(data) {
    console.log('что уходит dataAll', data)
    return baseRequest(
        `/api/create-batch-processes-by-call-center`,
        {
            method: 'POST',
            body: {...data},
        }
    );
}

export function fakeSegments() {
    return [{
    id:'7b9e54bb-e796-4e96-bb21-9fa45a6098d5',
    name: "РКЦ работа по сегменту Дни рождения от 06.06.23",
    segment: {
        id: '8895cb39-9769-42ba-99e0-c050ca58079f',
        name: 'Deal',
        filter:"executor=birthday",
        mapping: [],
    },
    profit: {transactionAmount: '1', moneyIncome: '2', deposit: '3'},
    quantity: '3',
    statusForLeads: {stageNew: '2', stageInProgress: '1', stageNotDialing: '4', stageFalse: '3', stageSuccess: '6'},
    statusForDeals: {stageInProgressDeals: '3', stageSuccessDeals: '2', stageFalseDeals: '1'},
    selectedAdmin: [],
},
    {
        id:'7b9e54bb-e796-4e96-bb21-9fa45a6098d6',
        name: "РКЦ работа по сегменту Дни рождения от 06.06.24",
        segment: {
            id: '2',
            name: 'NotDeal',
            filter:"executor=birthday",
            mapping: [],
        },
        profit: {transactionAmount: '1', moneyIncome: '2', deposit: '3'},
        quantity: '3',
        statusForLeads: {stageNew: '2', stageInProgress: '1', stageNotDialing: '4', stageFalse: '3', stageSuccess: '6'},
        statusForDeals: {stageInProgressDeals: '3', stageSuccessDeals: '2', stageFalseDeals: '1'},
        selectedAdmin: [],
    }]}

export function getBitrixEmployeesByDepartmentId() {
    return baseRequest(`/api/bitrix-employees`);
}