import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Drawer} from "@material-ui/core";
import * as PropTypes from 'prop-types';

export default
@withStyles(theme => ({
    info: {
        minHeight: 200,
        marginBottom:  theme.spacing(2),
        padding:  theme.spacing(1),
    },
    infoChange: {
        height: "100vh",
        marginBottom:  theme.spacing(2),
        padding:  theme.spacing(1),
    },
    drawerPaper: {
        position: 'relative',
        width: 230,
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
}))
class RightBar extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        navigationLinks: PropTypes.element,
        hidePrint: PropTypes.bool,
    };

    static defaultProps = {
        hidePrint: false,
    };

    render() {
        const { classes, children, navigationLinks, hidePrint } = this.props;

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                className={hidePrint ? classes.hidePrint : null}
            >
                <div className={`${navigationLinks === undefined ? classes.infoChange : classes.info}`}>{children}</div>
            </Drawer>
        );
    }
}
