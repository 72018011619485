import React, {useEffect, useState} from 'react';
import {Typography} from "@material-ui/core";

function FormatDate() {
    const [date, setDate] = useState(new Date());

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'Europe/Moscow' };
        return new Date(date).toLocaleDateString(options);
    };

        useEffect(() => {
            const intervalId = setInterval(() => {
                setDate(new Date());
            }, 1000);

            return () => clearInterval(intervalId);
        }, []);

    return (
        <div>
            <Typography style={{fontWeight: "700", display: "block"}}>{formatDate(date)}</Typography>
        </div>
    );
}

export default FormatDate;