import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import CheckboxField from "../../../components/fields/CheckboxField";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    checkbox: {
        alignItems: 'flex-start',
    }
}));

const SegmentCheckboxes = ({ showOnlyManagers, showOnlySalon, handleShowOnlyManagers, handleShowOnlySalon }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction={"column"}>
            <Grid item >
                <CheckboxField
                    value={showOnlyManagers}
                    onChange={handleShowOnlyManagers}
                    label={t("Employees Call center")}
                    disabled={showOnlySalon}
                    className={classes.checkbox}
                />
            </Grid>
            <Grid item style={{marginTop:"-20px"}}>
                <CheckboxField
                    value={showOnlySalon}
                    onChange={handleShowOnlySalon}
                    label={t("Quality control department")}
                    disabled={showOnlyManagers}
                    className={classes.checkbox}
                />
            </Grid>
        </Grid>
    );
}

export default SegmentCheckboxes;