import React, {useState, useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";
import RightBar from "../../components/left-bar/RightBar";
import SegmentContext from "./SegmentContext";
import ActionButton from "../../components/button/ActionButton";
import {getBitrixEmployeesByDepartmentId} from "../../services/area";
import TitleBar from "./rightComponents/TitleBar";
import SegmentCheckboxes from "./rightComponents/SegmentCheckboxes";
import AdminList from "./rightComponents/AdminList";
import ModalDialog from "./rightComponents/ModalDialog";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    right: {
        width: "calc(100% - 83.33%)",
    },
    drawerPaper: {
        width: "100%",
    },
}));

const Right = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [admins, setAdmins] = useState([]);
    const { selectedSegments, setSelectedSegments, selectedAdmin, setSelectedAdmin, selectedSegment, setSelectedSegment } = useContext(SegmentContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [selectedAdminModal, setSelectedAdminModal] = useState([]);
    const [showOnlyManagers, setShowOnlyManagers] = useState(false);
    const [showOnlySalon, setShowOnlySalon] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getBitrixEmployeesByDepartmentId();
                if (response.success) {
                    console.log('check response', response.data)
                    setAdmins(response.data);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();
    }, []);

    const handleClickOpen = () => {
        setModalOpen(true);
    }

    const handleClickClose = () => {
        setModalOpen(false);
    }

    const handleClickSave = () => {
        const selectedAdminObjects = admins.filter((admin) => selectedAdminModal.includes(admin.id));
        const selectedAdminNames = selectedAdminObjects.map((selectedAdminObjects) => ({
            id: selectedAdminObjects.id,
            name: selectedAdminObjects.name
        }));

        const sortedData = selectedSegments.map((segment) => {
            if (selectedSegment.includes(segment.id)) {
                return {
                    ...segment,
                    selectedAdmins: selectedAdminNames,
                };
            } else {
                return segment;
            }
        });

        handleClickClose();
        setSelectedAdminModal(selectedAdminModal);
        setSelectedAdmin(selectedAdminNames);
        setSelectedSegments(sortedData);
    };

    const handleSegmentChange = (event) => {
        const segmentValue = event.target.value;

        setSelectedSegment(segmentValue);
        setFormValid(segmentValue !== "" && selectedAdmin.length !== 0);
    }

    const handleAdminChange = (event) => {
        const adminValue = event.target.value;

        setSelectedAdminModal(adminValue);
        setFormValid(selectedSegment !== "" && adminValue.length > 0);
    };

    const handleShowOnlyManagers = () => {
        if (!showOnlyManagers) {
            setShowOnlySalon(false);
            setShowOnlyManagers(true);
        } else {
            setShowOnlyManagers(false);
        }
    };

    const handleShowOnlySalon = () => {
        if (!showOnlySalon) {
            setShowOnlyManagers(false);
            setShowOnlySalon(true);
        } else {
            setShowOnlySalon(false);
        }
    };

    useEffect(() => {
        setSelectedSegment([]);
        setSelectedAdminModal([]);
    }, [modalOpen, setSelectedSegment]);

    return (
        <div className={classes.right}>
            <RightBar classes={{drawerPaper: classes.drawerPaper}}>
                <TitleBar />
                <ActionButton onClick={() => handleClickOpen()}>{t("Distribute")}</ActionButton>
                <SegmentCheckboxes
                    handleShowOnlyManagers={handleShowOnlyManagers}
                    handleShowOnlySalon={handleShowOnlySalon}
                    showOnlyManagers={showOnlyManagers}
                    showOnlySalon={showOnlySalon}
                />
                <AdminList
                    admins={admins}
                    howOnlySalon={showOnlySalon}
                    showOnlyManagers={showOnlyManagers}
                    showOnlySalon={showOnlySalon}
                />
                <ModalDialog
                    modalOpen={modalOpen}
                    handleClickClose={handleClickClose}
                    handleClickSave={handleClickSave}
                    formValid={formValid}
                    selectedSegments={selectedSegments}
                    selectedSegment={selectedSegment}
                    selectedAdminModal={selectedAdminModal}
                    handleSegmentChange={handleSegmentChange}
                    handleAdminChange={handleAdminChange}
                    admins={admins}
                />
            </RightBar>
        </div>
    );
}

export default Right;