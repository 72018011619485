import React, {useContext, useEffect, useState} from "react";
import PageTitle from "../../components/typography/PageTitle";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Grid, IconButton} from "@material-ui/core";
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import StopCircleOutlinedIcon from '@material-ui/icons/Stop';
import DataTableLocal from "../../components/data-table/DataTableLocal";
import SegmentContext from "./SegmentContext";
import FormatDate from "./FormatDate";
import {RefreshSharp} from "@material-ui/icons";
import {StopOutlined} from "@material-ui/icons";
import {RemoveCircleOutlineSharp} from "@material-ui/icons";
import Brightness1SharpIcon from '@material-ui/icons/Brightness1Sharp';
import {
    getBitrixEmployeesByDepartmentId,
    getProcessesForTable,
    saveAllProcess,
    saveRowProcess, stopProcessesByCallCenter, stopRowProcessByCallCenter
} from "../../services/area";
import {Dialog, DialogContentText, DialogContent, DialogTitle, Button} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        width: "250px",
        height: "48px",
        marginLeft: "8px",
        marginTop: "10px",
        marginBottom: "50px",
        display: "flex",
        flexDirection: "column",
    },
    containerItem: {
        display: "flex",
        justifyContent: "space-between",
    },
    containerTable: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    startBtn: {
        width: "14px",
        height: "16px",
        marginTop: "4px",
    },
    startIcon: {
        color: "#3c1a53",
    },
    circleBtn: {
        width: "20px",
        height: "20px",
        color: "#3c1a53",
        marginBottom: "-8px",
    },
}));

const Body = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {selectedSegments, setSelectedSegments, selectedAdmin, selectedSegment} = useContext(SegmentContext);
    const [tableRefresh, setTableRefresh] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [playButtonClick, setPlayButtonClick] = useState(false);

    useEffect(() => {
        Promise.all([getBitrixEmployeesByDepartmentId(), getProcessesForTable()]).then(([rAdmin, rProcess]) => {
            if (rAdmin.success && rProcess.success) {
                setSelectedSegments(rProcess.data.processes.map((pr) => {
                    return {
                        ...pr,
                        selectedAdmin: pr.selectedAdmins.map((id) => rAdmin.data.find(a => a.id === id)),
                        work: 'working',
                    }
                }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRefresh]);

    useEffect(() => {
        const newData = selectedSegments.map((segment) => {
            if (selectedSegment.includes(segment.id)) {
                return {
                    ...segment,
                    selectedAdmin: selectedAdmin,
                };
            } else {
                return {
                    ...segment,
                }
            }
        });

        setSelectedSegments(newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAdmin]);

    const handleStopAll = () => {
        const processesStop = selectedSegments.map((process) => {
            if (process.work !== undefined) {
                return {
                    id: process.id,
                    name: process.name,
                    profit: process.profit,
                    quantity: process.quantity,
                    segment: {
                        id: process.segment?.id,
                    },
                    selectedAdmins: process.selectedAdmins,
                    statusForDeals: process.statusForDeals,
                    statusForLeads: process.statusForLeads
                }
            } else {
                return false;
            }
        }).filter(Boolean);

        console.log('processesStop', processesStop);
        stopProcessesByCallCenter(processesStop).then(response => {
            if (response.success === false) {
                console.log('failed', response.error.message);
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const handleStopRow = (itemRow) => {
        const stopRow = {
            id: itemRow.id,
            name: itemRow.name,
            profit: itemRow.profit,
            quantity: itemRow.quantity,
            segment: {
                id: itemRow.segment?.id,
            },
            selectedAdmins: itemRow.selectedAdmins,
            statusForDeals: itemRow.statusForDeals,
            statusForLeads: itemRow.statusForLeads
        };
        console.log('stopRow', stopRow);
        if (itemRow.blank === true){
            setDialogTitle('Ошибка!');
            setDialogMessage('Создайте процесс, чтобы остановить его');
            setModalOpen(true);
        }
        stopRowProcessByCallCenter(stopRow).then(response => {
            if (response.success === false) {
                console.log('failed', response.error.message);
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const handleSaveAll = () => {
        const processes = selectedSegments.map((segment) => {
            if (segment.work === undefined) {
                return {
                    id: "",
                    name: null,
                    profit: null,
                    quantity: "",
                    segment: {
                        id: segment.id,
                        whatToDo: segment.whatToDo
                    },
                    selectedAdmins: segment.selectedAdmin,
                    statusForDeals: null,
                    statusForLeads: null
                }
            } else {
                return false;
            }
        }).filter(Boolean);
        const segmentsWithoutSelectedAdmin = selectedSegments.filter(segment => !segment.selectedAdmin);

        if (segmentsWithoutSelectedAdmin.length === 0) {
            setPlayButtonClick(true);
            setDialogTitle('Успех');
            setDialogMessage('Все процессы успешно сохранены.');
            setModalOpen(true);
        } else {
            const segmentNames = segmentsWithoutSelectedAdmin.map(segment => segment.name).join(', ');
            setDialogTitle('Ошибка');
            setDialogMessage(`Не выбран администратор в процессе(ах) с сегментом(ми): ${segmentNames}`);
            setModalOpen(true);
        }

        let processRows = {
            processes: processes
        }

        saveAllProcess(processRows).then(response => {
            if (response.success === false) {
                console.log('failed', response.error.message);
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const handleSaveRow = (itemRow) => {
        const processRow = {
            id: "",
            name: itemRow.name !== undefined ? itemRow.name : null,
            profit: null,
            quantity: "",
            segment: {
                id: itemRow.id !== undefined ? itemRow.id :itemRow.segment.id,
                whatToDo: itemRow.whatToDo
            },
            selectedAdmins: itemRow.selectedAdmins,
            statusForDeals: null,
            statusForLeads: null
        };

        if (!!processRow.selectedAdmins === false) {
            setDialogTitle('Ошибка!');
            setDialogMessage(`Выберите администратора в процессе с сегментом ${processRow.name}`);
            setModalOpen(true);
        } else if (itemRow.blank !== true){
            setDialogTitle('Ошибка!');
            setDialogMessage('Процесс уже запущен');
            setModalOpen(true);
        }
        else {
            setDialogTitle('Успех!');
            setDialogMessage(`Процесс с сегментом ${processRow.name} успешно создан`);
            setModalOpen(true);
        }

        saveRowProcess(processRow).then(response => {
            if (response.success === false) {
                console.log('failed', response.error.message);
            }
        })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const handleCloseModal = () => {
        setDialogTitle('');
        setDialogMessage('');
        setModalOpen(false);
    };

    const handleDeleteAdmin = (adminId, itemRow) => {
        const selectedId = itemRow.id;
        const updatedSegments = selectedSegments.map((segment) => {
            if (Array.isArray(segment.selectedAdmins) && segment.id === selectedId) {
                return {
                    ...segment,
                    selectedAdmins: segment.selectedAdmins.filter((admin) => admin.id !== adminId),
                };
            }
            return segment;
        });
        setSelectedSegments(updatedSegments);
    };

    const handleTableRefresh = () => {
        setPlayButtonClick(false);
        setTableRefresh(!tableRefresh);
    };

    const columnData = [
        {
            id: 'id',
            numeric: false,
            disablePadding: false,
            label: 'Process',
            processValue: (value, t, itemRow) => {
                return <>
                    <Brightness1SharpIcon className={classes.circleBtn}/>
                    <IconButton className={classes.startBtn} onClick={() => handleSaveRow(itemRow)} disabled={playButtonClick}>
                        <PlayArrowOutlinedIcon className={classes.startIcon}/>
                    </IconButton>
                    <IconButton className={classes.startBtn}>
                        <StopCircleOutlinedIcon className={classes.startIcon} onClick={() => handleStopRow(itemRow)}/>
                    </IconButton>
                </>
            }
        },
        {
            id: 'name', numeric: false, disablePadding: false, label: 'Segment', processValue: (name) => {
                return <div>
                    {name}
                </div>
            }
        },
        {
            id: 'profit', numeric: false, disablePadding: false, label: 'Profit', subColumns: [
                {id: 'transactionAmount', numeric: false, disablePadding: false, label: 'Transaction amount'},
                {id: 'moneyIncome', numeric: false, disablePadding: false, label: 'Price for deal'},
                {id: 'deposit', numeric: false, disablePadding: false, label: 'Deposit'}
            ]
        },
        {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
        {
            id: 'statusForLeads', numeric: false, disablePadding: false, label: 'Status For Leads', subColumns: [
                {id: 'leadsStatusNew', numeric: false, disablePadding: false, label: 'Stage New'},
                {id: 'leadsStatusInProcess', numeric: false, disablePadding: false, label: 'In Work'},
                {id: 'leadsStatusCallFail', numeric: false, disablePadding: false, label: 'Stage Not Dialing'},
                {id: 'leadsStatusCancelled', numeric: false, disablePadding: false, label: 'Stage False'},
                {id: 'leadsStatusSuccess', numeric: false, disablePadding: false, label: 'Went Into a Deal'},
            ]
        },
        {
            id: 'statusForDeals', numeric: false, disablePadding: false, label: 'Status For Deals', subColumns: [
                {id: 'dealsStatusInProcess', numeric: false, disablePadding: false, label: 'In Work'},
                {id: 'dealsStatusSuccess', numeric: false, disablePadding: false, label: 'Stage Success'},
                {id: 'dealsStatusCancelled', numeric: false, disablePadding: false, label: 'Stage False'},
            ]
        },
        {
            id: 'selectedAdmins',
            numeric: false,
            disablePadding: false,
            label: 'Performers',
            processValue: (value, t, itemRow) => {
                const adminNames = value?.map((admin) => admin.name).join(',\n');
                if (value?.length > 1) {
                    return (
                        <>
                            {value.map((admin, index) => (
                                <React.Fragment key={admin.id}>
                                    <span>{admin.name}</span>
                                    {index < value.length - 1 && ',\n'}
                                    <IconButton className={classes.startBtn}
                                                onClick={() => handleDeleteAdmin(admin.id, itemRow)}>
                                        <RemoveCircleOutlineSharp className={classes.startIcon}/>
                                    </IconButton>
                                    <br/>
                                </React.Fragment>
                            ))}
                        </>
                    );
                } else if (value?.length === 1) {
                    return (
                        <>
                            <span>{adminNames}</span>
                            <IconButton className={classes.startBtn}
                                        onClick={() => handleDeleteAdmin(value[0].id, itemRow)}>
                                <RemoveCircleOutlineSharp className={classes.startIcon}/>
                            </IconButton>
                        </>
                    );
                } else {
                    return null;
                }
            }
        },
    ];

    return (
        <>
            <Grid style={{width:"calc(100% - 37.5%)"}}>
                <div className={classes.container}>
                    <div className={classes.containerItem}>
                        <PageTitle>{t("Day plan")}</PageTitle>
                        <IconButton className={classes.startBtn} onClick={handleSaveAll} disabled={playButtonClick}>
                            <PlayArrowOutlinedIcon className={classes.startIcon}/>
                        </IconButton>
                        <IconButton className={classes.startBtn} onClick={handleStopAll}>
                            <StopOutlined className={classes.startIcon}/>
                        </IconButton>
                        <IconButton className={classes.startBtn} onClick={handleTableRefresh}>
                            <RefreshSharp className={classes.startIcon}/>
                        </IconButton>
                    </div>
                    <div className={classes.containerItem}>
                        <FormatDate/>
                        {/*<CalendarMonthOutlinedIcon/>*/}
                    </div>
                </div>
                <div className={classes.containerTable}>
                    <DataTableLocal
                        key={tableRefresh}
                        data={selectedSegments}
                        selector={false}
                        columnData={columnData}
                        handleSaveRow={handleSaveRow}
                    />
                </div>
                {modalOpen && (
                    <Dialog
                        open={modalOpen}
                        onClose={handleCloseModal}
                    >
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{dialogMessage}</DialogContentText>
                        </DialogContent>
                        <Button onClick={handleCloseModal}>{t("Okay")}</Button>
                    </Dialog>
                )}
            </Grid>
        </>
    );
}

export default Body;