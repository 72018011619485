import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Grid,
    Typography,
    Button, makeStyles
} from "@material-ui/core";
import SelectFromItemsField from "../../../components/fields/SelectFromItemsField";
import AutocompleteMultiSelectField from "../../../components/fields/AutocompleteMultiSelectField";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    modal: {
        alignItems: 'flex-start',
    },
    paper: {
        height: '100vh',
    },
    fullWidth: {
        width: '100%',
    },
}));

const ModalDialog = ({ modalOpen, handleClickClose, handleClickSave, formValid, selectedSegments, selectedSegment, selectedAdminModal, handleSegmentChange, handleAdminChange, admins }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <>
        {modalOpen === true && (
            <Dialog open={modalOpen} onClose={handleClickClose} classes={{container: classes.modal, paper: classes.paper}} scroll={"paper"}>
                <DialogTitle>{t("Distribution of employees by tasks")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("Select a segment")}
                    </DialogContentText>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{t("Segment")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFromItemsField
                                items={selectedSegments}
                                value={selectedSegment}
                                onChange={handleSegmentChange}
                                className={classes.fullWidth}
                            />
                        </Grid>
                    </Grid>
                    <DialogContentText>
                        {t("Select administrator")}
                    </DialogContentText>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{t("Administrator")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteMultiSelectField
                                options={admins}
                                value={selectedAdminModal}
                                onChange={handleAdminChange}
                                className={classes.fullWidth}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleClickSave} color="primary" disabled={!formValid}>
                        {t("Distribute")}
                    </Button>
                </DialogActions>
            </Dialog>
        )}
        </>
);
}

export default ModalDialog;