import React, {useState} from "react";
import Left from "./Left";
import Body from "./Body";
import Right from "./Right";
import SegmentContext from "./SegmentContext";

export default function PersonalArea() {
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [tableData, setTableData] = useState([]);

    return (
        <SegmentContext.Provider value={{ selectedSegments, setSelectedSegments, selectedAdmin, setSelectedAdmin, selectedSegment, setSelectedSegment, tableData, setTableData }}>
        <>
            <Left />
            <Body />
            <Right />
        </>
        </SegmentContext.Provider>
    );
}