import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell } from "@material-ui/core";
import moment from "moment";
import _ from 'lodash';
import {getDownloadLink} from "../../services/storage";
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import cn from 'classnames';


const styles = theme => ({
    buttonLink: {
        color: theme.palette.primary.main,
    },
    column: {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.grey[400],
        borderBottomColor: theme.palette.grey[400],

        '&:last-child': {
            borderRight: 'none',
        }
    },
    subColumn: {
        borderRightWidth: 1,
        borderRightStyle: 'dashed',
        borderRightColor: theme.palette.grey[300],
        borderBottomColor: theme.palette.grey[400],

        '&:last-child': {
            borderRight: 'none',
        }
    },
    colorSquare: {
        display: 'inline-block',
        height: 15,
        width: 15,
        marginRight: 5
    }
});

function isCanConvertToJson(value) {
    if (_.isPlainObject(value) && !React.isValidElement(value)) {
        return true;
    }

    if (_.isArray(value) && value.length) {
        return value.every(item => (_.isPlainObject(item) || _.isArray(item)) && !React.isValidElement(item)  );
    }

    return false;
}

export default
@withStyles(styles)
@withTranslation()
class DataTableCell extends React.PureComponent
{
    static propTypes = {
        idx: PropTypes.number.isRequired,
        col: PropTypes.object.isRequired,
        linkTemplate: PropTypes.func,
        itemRow: PropTypes.any,
        onClick: PropTypes.func,
        itemIdx: PropTypes.number,
        className: PropTypes.string,
    };

    renderColumn = (value, key, col, className) => {
        const { linkTemplate, idx, itemRow, onClick, classes, itemIdx, t }  = this.props;

        let style = {};

        if (value && col.dateFormat && (!itemRow.isParentRow || (itemRow.isParentRow && !col.parentProcessValue)) ) {
            value = moment(value).format(col.dateFormat);
        }

        if (value && col.color) {
            if(typeof col.color === 'function') {
                style.color = col.color(value, t, itemRow)
            } else {
                style.color = value;
            }
            style.verticalAlign = 'middle';
            value = <div><span className={classes.colorSquare} style={{ backgroundColor: value }}> </span>{value}</div>;
        } else if (value && col.colorField) {
            const colorValue = _.get(itemRow, col.colorField, null);
            style.color = colorValue;
            style.verticalAlign = 'middle';
            value = <div><span className={classes.colorSquare} style={{ backgroundColor: colorValue }}> </span>{value}</div>;
        }

        if (value && col.files) {
            if (Array.isArray(value)) {
                value = value.map(file => <div key={file.id}><a href={getDownloadLink(file.id)}>{file.name}</a></div>);
            } else {
                value = <div><a href={getDownloadLink(value.id)}>{value.name}</a></div>;
            }
        }

        if (_.isBoolean(value)) {
            value = value ? t("Yes"): t("No");
        }

        if (col.processValue && (!itemRow.isParentRow || (itemRow.isParentRow && !col.parentProcessValue))) {
            value = col.processValue(value, t, itemRow, itemIdx);
        }

        if (col.parentProcessValue && itemRow.isParentRow) {
            value = col.parentProcessValue(value, t, itemRow);
        }

        //Чтобы ошибка не вываливалась, пусть лучше отображает
        if (isCanConvertToJson(value) && !col.color) {
            value = <pre>{JSON.stringify(value, null, 2)}</pre>;
        }

        if (col.multiline && typeof value === "string") {
            value = value.split("\n").map((item, itemIdx) => <div key={itemIdx}>{item}</div>)
        }

        let component = "td";
        if (idx === 0) {
            component = "th";

            if (linkTemplate && !itemRow.isParentRow) {
                const link = linkTemplate(itemRow, itemIdx);

                if (_.isPlainObject(link)) {
                    value = <Link {...link}>{value}</Link>;
                } else if (link) {
                    value = <Link to={link}>{value}</Link>;
                }
            }

            if (!linkTemplate && onClick) {
                value = <Link to="#" onClick={event => onClick(itemRow, itemIdx, event)} className={classes.buttonLink}>{value}</Link>;
            }
        } else {
            if (col.linkTemplate && !itemRow.isParentRow) {
                const link = col.linkTemplate(itemRow, itemIdx);

                if (link) {
                    value = <Link to={col.linkTemplate(itemRow, itemIdx)}>{value}</Link>;
                }
            }

            if (!col.linkTemplate && col.onClick) {
                value = <Link to="#" onClick={event => col.onClick(itemRow, itemIdx, event)} className={classes.buttonLink}>{value}</Link>;
            }
        }

        if (col.minWidth) {
            style.minWidth = col.minWidth;
        }

        if (col.width) {
            style.width = col.width;
        }

        if (col.wordBreak) {
            style.wordBreak = 'break-word';
        }

        return <TableCell
            key={key}
            component={component}
            scope="row"
            align={col.numeric ? "right": "left"}
            padding={col.disablePadding ? 'none' : 'default'}
            className={className}
            style={style}
        >{value}</TableCell>;
    };

    render() {
        const { col, itemRow, classes, itemIdx, className }  = this.props;

        let value = _.get(itemRow, col.id, null);

        if (col.subColumns) {
            return col.subColumns.map((subColumn, subIdx) =>
                this.renderColumn(
                    _.get(value, subColumn.id, null),
                    `${itemIdx}-${col.id}-${subColumn.id}`,
                    subColumn,
                    cn(subIdx === col.subColumns.length-1 ? classes.column : classes.subColumn, className)
                )
            );
        } else {
            return this.renderColumn(value, `${itemIdx}-${col.id}`, col, cn(classes.column, className));
        }

    }
}
