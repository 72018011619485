import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import PageTitle from "../../../components/typography/PageTitle";
import Brightness1SharpIcon from "@material-ui/icons/Brightness1Sharp";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    mainCircle: {
        color: "#621F6F",
        width: "45px",
        height: "45px",
    },
}));

const TitleBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <PageTitle>{t("Operators")}</PageTitle>
            <Brightness1SharpIcon className={classes.mainCircle}/>
        </Grid>
    );
}

export default TitleBar;